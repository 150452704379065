<template>
<home-page
  :logo-pic="require('/src/assets/dooby-life-logo.png')">
</home-page>
</template>

<script>

import HomePage from "./components/HomePage.vue";
export default {
  name: 'App',
  components: {
    HomePage
  }
}
</script>

<style>
body {
  background-color: rgb(67, 67, 122);
  max-height: 500px;
}
</style>

<template>
  <div>
      <div class="tcontainer"><div class="ticker-wrap"><div class="ticker-move">
  <div class="ticker-item">#DoobyLife</div>
  <div class="ticker-item">#MyDoctorSaidYes</div>
</div></div></div>
<div class=logo-container>
    <img class="logo" :src="logoPic" alt="dooby-life" />
    </div>
    <h2>Copyright Big City Productions LLC, All Rights Reserved</h2>
  </div>
</template>

<script>
export default {
  props: ["logoPic"],
};
</script>

<style scoped>
h2 {
    margin: auto;
    display: flex;
    justify-content: center;
    padding: 20px;
    text-align: center;
}

.logo {
    max-width: 350px;
    margin: auto;
}

.logo-container {
  display: flex;
}

/* OUTER CONTAINER */
.tcontainer {
  width: 100%;
  overflow: hidden; /* Hide scroll bar */
  background-color: rgb(67, 67, 122);
  margin-top: 40px;
}
 
/* MIDDLE CONTAINER */
.ticker-wrap {
  width: 100%;
  padding-left: 100%; /* Push contents to right side of screen */
  background-color: rgb(67, 67, 122);
}

/* INNER CONTAINER */
@keyframes ticker {
  0% { transform: translate3d(0, 0, 0); }
  100% { transform: translate3d(-100%, 0, 0); }
}
.ticker-move {
  /* Basically move items from right side of screen to left in infinite loop */
  display: inline-block;
  white-space: nowrap;
  padding-right: 100%;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: ticker;
  animation-duration: 10s;
}
.ticker-move:hover{
  animation-play-state: paused; /* Pause scroll on mouse hover */
}

/* ITEMS */
.ticker-item{
  display: inline-block; /* Lay items in a horizontal line */
  padding: 0 2rem;
  color: white;
  font-size: 40px;
}
</style>
